import React, { Component } from "react";
// import {Link} from "react-router-dom";

class EventDetailsItem extends Component {
  render() {
    return (
      <div className="list-item event">
        <div>
          <img src={`/images/service-types/${this.props.serviceType}.png`} className="item-thumbnail" alt="" width="32" height="32" />
          <h3 style={{ display: "inline-block" }} dangerouslySetInnerHTML={{ __html: this.props.name }}></h3>
          <h3 style={{ display: "inline-block" }}>
            <small>{this.props.date}</small>
          </h3>
        </div>
        <div>
            {this.props.content.map(function(object, i) {
                return <p>{object}</p>
            })}
        </div>
        <div>
            {this.props.sundayClubs === true &&
                <p>There will be Sunday Clubs for the Children during the service.</p>
            }
            {this.props.sundayClubs === false &&
                <p>There will be <strong>NO</strong> Sunday Clubs for children during the service today.</p>
            }
          {this.props.teaAndCoffee === true &&
              <p>There will be tea, coffee, and fellowship after the service.</p>
          }
        </div>
        <div className="tags">
          {this.props.tags.split(",").map((step) => (
            <span className="tag">{step}</span>
          ))}
        </div>
      </div>
    );
  }
}

export default EventDetailsItem;
