import "./ParishProfileAndVacancyBlock.css"

const ParishProfileAndVacancyBlock = () => {
    return (
        <div className="col-md-12 row p-5 parish-profile text-center">
            <p className="col-md-3">&nbsp;</p>
            <div className="col-md-9 p-5">
                <p>
                    With the imminent approach of our Vicar's retirement, we are looking for a dynamic leader to inspire
                    our future at St Michael's.
                </p>
                <p>
                    To learn more about our parish life and worship,&nbsp;
                    <a href="/files/parish_profile_2025.pdf">click here to download our parish profile</a>
                </p>
            </div>
        </div>
    );
};

export default ParishProfileAndVacancyBlock;