import { Component } from "react";

import PageTitle from "../components/page_title/PageTitle";
import EventDetailsItem from "../data/EventDetailsItem";
import "./NewsPage.css";

const eventData = require("../events.json");
let eventDetailsItems;

class NewsPage extends Component {
  getEventDetailsItem(item) {
    console.log(item);
    eventDetailsItems.push(
      <EventDetailsItem
        name={item.name}
        serviceType={item.serviceType}
        date={item.date}
        content={item.content}
        sundayClubs={item.sundayClubs}
        teaAndCoffee={item.teaAndCoffee}
        tags={item.tags}
      />
    );
    eventDetailsItems.push(<hr/>);
  }

  constructor(props) {
    super(props);

    eventDetailsItems = [];
    eventData.events.forEach(this.getEventDetailsItem);
    eventDetailsItems.splice(-1);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section id="news-page">
        <PageTitle text="News &amp; Events" />

        <div className="fullwidth-block">
          <article className="container">
            <div className="row">
              <div className="col-md-9">{eventDetailsItems}</div>
              <div className="col-md-3">
                <div className="document-download">
                  <div className="download-thumbnail">
                    <a href="/files/services/newsletter.pdf" target="_blank" rel="noreferrer">
                      <h5>Latest Newsletter</h5>
                      <img src="/files/services/newsletter-thumb.jpg" alt="" />
                      <p>
                        <i className="fa fa-download" />
                        Download
                      </p>
                    </a>
                  </div>
                </div>
                <br />
                <div className="document-download">
                  <div className="download-thumbnail">
                    <a href="/files/services/readings.pdf" target="_blank" rel="noreferrer">
                      <h5>This Week's Readings</h5>
                      <img src="/files/services/readings-thumb.jpg" alt="" />
                      <p>
                        <i className="fa fa-download" />
                        Download
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    );
  }
}

export default NewsPage;
